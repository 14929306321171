/* You can add global styles to this file, and also import other style files */
:root
{
    font-size:10pt;
}

.selected {
    border-style: solid;
    border-radius: 16px;
    border-color: var(--primary-color);
    border-width: 2px;
}

.fill-vert {
    min-height: 100%;
    max-height: 100%;
    height:100%;
}

.scroll-overflow {

    overflow-y:auto;
    overflow-x:auto;
}

.no-overflow {
    overflow:hidden;
}

.wrap {
    word-break:break-all;
}

.no-wrap{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.content-panel{
    min-height: 100%;
    height: 100%;
    max-height: 580px;
}

.content-panel-component{
    min-height: 580px ;
    max-height: 580px;
    height:580px;
}

.dark-panel {.p-fieldset-content { background-color: whitesmoke; }}

// Shrinks the padding around p-inplace editing fields
.p-inplace .p-inplace-display {
  padding: .2em !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: .3em;
}

body, div {
  font-family: sans-serif;
}

.category-header {
  text-transform: uppercase;
  border-bottom: 1px solid black;
  font-weight: bold;
}

a:link {
  text-decoration: none;
}

a:hover {
  display: block;
  background-color: lightgrey;
}

.data-table {
  width: 100%;
  margin-bottom: 30px;
}

.field-name {
  width: 20%;
  vertical-align: top;
  text-align: right;
  color: #555;
  background: #ffffff;
}

.field-data {
  vertical-align: top;
  font-weight: bold;
  color: black;
  width: 80%;
}

tr:nth-child(even) {
  background: #eeeeee;
}

tr:nth-child(odd) {
  background: #ffffff;
}

.workbench-bold {
  font-weight: bold;
}

// // Make sure dialogs are on top of the root menu
// :host ::ng-deep  
// {
//   z-index: 999999;
// }
